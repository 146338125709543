import { createPagesBrowserClient } from "@supabase/auth-helpers-nextjs";
import { useRouter } from "next/router";
import { useState } from "react";
import { ConnectHotel } from "~/components/authentication/connect-hotel";
import { Loading } from "~/components/common/Loading";
import { useConfigContext } from "~/components/common/config/config-context";
import { api } from "~/utils/api";

export default function Home() {
  const router = useRouter();
  const supabase = createPagesBrowserClient();
  const { userProfile } = useConfigContext();
  const [redirected, setRedirected] = useState(false);
  const [failedToRedirect, setFailedToRedirect] = useState(false);

  const { data: allowedHotelList, isLoading } =
    api.reports.getHotelList.useQuery(undefined, {
      enabled: !!userProfile,
      placeholderData: (prev) => prev ?? undefined,
    });

  const logout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error("Error logging out:", error.message);
    } else {
      // await router.replace("/login");
      router.reload();
    }
  };

  const redirectToPreferredHotel = async () => {
    let hotelId;
    if (allowedHotelList?.length === 1) {
      hotelId = allowedHotelList[0]?.id;
      console.log(
        `muly:redirectToPreferredHotel only one picked it ${hotelId}`,
      );
    } else if (allowedHotelList && allowedHotelList?.length > 1) {
      const lastSelectedHotelId = window.sessionStorage.getItem("hotelId");
      if (
        lastSelectedHotelId &&
        allowedHotelList.find((h) => h.id === lastSelectedHotelId && !h.isGroup)
      ) {
        hotelId = lastSelectedHotelId;
        console.log(
          `muly:redirectToPreferredHotel found in sessionStorage ${hotelId}`,
        );
      } else {
        const lastUsedHotelId = window.localStorage.getItem("hotelId");
        if (
          lastUsedHotelId &&
          allowedHotelList.find((h) => h.id === lastUsedHotelId && !h.isGroup)
        ) {
          hotelId = lastUsedHotelId;
          console.log(
            `muly:redirectToPreferredHotel found in localStorage ${hotelId}`,
            {
              userProfile,
              allowedHotelList,
            },
          );
        } else {
          hotelId = allowedHotelList[0]?.id;
          console.log(`muly:redirectToPreferredHotel picked 1st ${hotelId}`);
        }
      }
    }

    if (hotelId) {
      console.log(`muly:index:redirectToPreferredHotel`, { hotelId });
      await router.push(`/${hotelId}`);
    } else {
      console.log(`muly:index:setFailedToRedirect`);
      setFailedToRedirect(true);
    }
  };

  if (!userProfile) {
    console.log(`muly:Home:!user profile ${redirected}`);
    if (!redirected) {
      setRedirected(true);
      console.log(`muly:Home:!user profile: redirected`);
      router.replace("/login").catch((err) => {
        console.log(`muly:redirect to login`, { err });
      });
    }
  } else if (allowedHotelList) {
    if (!redirected && window.localStorage) {
      setRedirected(true);
      console.log(`muly:Home:allowedHotelList`, { allowedHotelList });
      redirectToPreferredHotel().catch((err) => {
        console.error(`muly:Home:redirectToPreferedHotel`, { err });
      });
    }
  }

  if (!failedToRedirect) {
    return <Loading />;
  } else {
    return <ConnectHotel />;
  }

  // console.log(`muly:Home:render`, { userProfile });
  // return (
  //   <>
  //     <Head>
  //       <title>Rate Quantum</title>
  //       <meta name="description" content="Autopilot" />
  //       <link rel="icon" href="/favicon.ico" />
  //     </Head>
  //     <main className="flex min-h-screen flex-col items-center justify-center bg-gradient-to-b from-[#CA3631] to-[#61191B]">
  //       <div className="container flex flex-col items-center justify-center gap-12 px-4 py-16 ">
  //         <h1 className="text-5xl font-extrabold tracking-tight text-white sm:text-[5rem]">
  //           Rate Quantum
  //         </h1>
  //         <SelectHotel onValueChange={handleSelectHotel} variant="outlined" />
  //         <Button onClick={logout}>Logout</Button>
  //         <pre>{JSON.stringify(userProfile)}</pre>
  //       </div>
  //     </main>
  //   </>
  // );
}

Home.disableLayout = true;
Home.disableNeedUser = true; // we need user, but we want to redirect to login page if not login
Home.disableNeedHotelData = true;
